import React, { useRef, useState } from 'react';
import { ReactZoomPanPinchProps, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from '@emotion/styled';

import { appendThumbnailLink } from 'helpers/watermarks/appendThumbnailLink';
import { LoadableContent, LoadableContentModes } from '../Loader';

interface Props {
  thumbnail: string;
  title: string;
  onClick?: () => void;
  disableZoom?: boolean;
}

const ThumbnailImage = styled.img`
  display: block;
  width: fit-content;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
`;

const ImageWatermark = styled.picture`
  position: relative;
  width: 100%;
  height: auto;

  div {
    width: 100%;
    justify-content: center;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;
const LoaderWrapper = styled.div`
  min-height: 38px;
  & > div {
    background: none;
    backdrop-filter: none;
  }
`;
const transformWrapperOptions = { minScale: 1, centerContent: false };

const ThumbnailLink = ({
  thumbnail,
  title,
  onClick,
  disableZoom = false,
}: Props): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const resetTransformRef = useRef<null | Function>(null);
  const [blockPan, setBlockPan] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleZoomChange: ReactZoomPanPinchProps['onTransformed'] = (ref, { scale }) => {
    const minScaleToUsePan = 1.1;

    if (scale < minScaleToUsePan && !blockPan) {
      setTimeout(() => {
        resetTransformRef.current?.();
        setBlockPan(true);
      }, 200);
    } else if (blockPan && scale >= minScaleToUsePan) setBlockPan(false);
  };

  return (
    <TransformWrapper
      {...transformWrapperOptions}
      disabled={disableZoom}
      onTransformed={handleZoomChange}
      panning={{ disabled: blockPan }}
      wheel={{ step: 100 }}
      doubleClick={{ mode: 'reset' }}
    >
      {({ resetTransform }) => (
        <ImageWatermark
          onClick={onClick}
          onLoad={() => {
            resetTransformRef.current = resetTransform;
          }}
        >
          <TransformComponent>
            <LoaderWrapper>
              <LoadableContent
                mode={LoadableContentModes.OVERLAY}
                loading={isLoading}
                drawContent
                size="S"
                hideLabel
              />
              <ThumbnailImage
                src={appendThumbnailLink(thumbnail)}
                alt={title}
                onLoad={() => setIsLoading(false)}
              />
            </LoaderWrapper>
          </TransformComponent>
        </ImageWatermark>
      )}
    </TransformWrapper>
  );
};

export { ThumbnailLink };
