import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const GalleryLayout = styled.div`
  display: grid;
  gap: 12px;

  @media ${MEDIA_QUERY.MAX_SM} {
    grid-template-columns: repeat(auto-fill, 162px);
  }

  @media ${MEDIA_QUERY.SM} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;

    & > picture {
      &:first-of-type {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ThumbImageWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;
